export var getSquareColors = function getSquareColors(disabled, checked, errorMessage, error) {
  if (disabled) {
    return ['tw-text-neutral-light-400', 'dark:tw-text-neutral-dark-500'];
  }
  if (checked) {
    return ['tw-text-primary-light-500 hover:tw-text-primary-light-400 active:tw-text-primary-light-600', 'dark:tw-text-secondary-light-500 dark:hover:tw-text-secondary-light-400 dark:active:tw-text-secondary-light-600'];
  }
  if (errorMessage || error) {
    return 'tw-text-alert-500';
  }
  return ['tw-text-neutral-light-500 hover:tw-text-primary-light-500', 'dark:tw-text-neutral-dark-400 dark:hover:tw-text-secondary-light-500'];
};