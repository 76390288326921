import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
var useIntersectionObserver = function useIntersectionObserver(elementRef, _ref) {
  var _ref$threshold = _ref.threshold,
    threshold = _ref$threshold === void 0 ? 0 : _ref$threshold,
    _ref$root = _ref.root,
    root = _ref$root === void 0 ? null : _ref$root,
    _ref$rootMargin = _ref.rootMargin,
    rootMargin = _ref$rootMargin === void 0 ? '0%' : _ref$rootMargin,
    _ref$freezeOnceVisibl = _ref.freezeOnceVisible,
    freezeOnceVisible = _ref$freezeOnceVisibl === void 0 ? false : _ref$freezeOnceVisibl;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    entry = _useState2[0],
    setEntry = _useState2[1];
  var frozen = (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && freezeOnceVisible;
  var updateEntry = function updateEntry(_ref2) {
    var _ref3 = _slicedToArray(_ref2, 1),
      entryToUpdate = _ref3[0];
    setEntry(entryToUpdate);
  };
  useEffect(function () {
    var node = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current;
    var hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || frozen || !node) return;
    var observerParams = {
      threshold: threshold,
      root: root,
      rootMargin: rootMargin
    };
    var observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);
    return function () {
      return observer.disconnect();
    };
  }, [elementRef, threshold, root, rootMargin, frozen]);
  return entry;
};
export default useIntersectionObserver;