import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import useAchievementByType, { PLAYROOM_ACHIEVEMENTS_BY_TYPE } from "../useAchievementByType";
import useIsPlayRoomActivated from "../useIsPlayRoomActivated";
var COMPLETE_COURSE = PLAYROOM_ACHIEVEMENTS_BY_TYPE.COMPLETE_COURSE,
  COMPLETE_PROJECT = PLAYROOM_ACHIEVEMENTS_BY_TYPE.COMPLETE_PROJECT,
  COMPLETE_QUIZ = PLAYROOM_ACHIEVEMENTS_BY_TYPE.COMPLETE_QUIZ,
  LEARNING_PATH = PLAYROOM_ACHIEVEMENTS_BY_TYPE.LEARNING_PATH;

/**
 * @description This hooks is designed to retrieve and organize information about achievement coins
 * earned in a specific organization's Playroom feature.
 * The hook first checks if the Playroom feature is activated for the specified organization using the
 * "useIsPlayRoomActivated" hook. Then, it uses the "useAchievementByType" hook to retrieve achievement
 * coin information for the specified organization, and filters the results by certain types of achievements.
 * Finally, the hook organizes the retrieved coin information by destructuring the "achievementCoins" array
 * and assigning default values to certain variables. These variables and the "isPlayroomEnabled" variable
 * are then returned as an object.
 */
var usePlayroomCoinEventValues = function usePlayroomCoinEventValues(organizationSlug, organizationId) {
  var _useIsPlayRoomActivat = useIsPlayRoomActivated(organizationSlug),
    isPlayroomEnabled = _useIsPlayRoomActivat.isActivated;
  var _useAchievementByType = useAchievementByType({
      organizationId: organizationId,
      by: [COMPLETE_COURSE, COMPLETE_PROJECT, COMPLETE_QUIZ, LEARNING_PATH],
      skip: !isPlayroomEnabled
    }),
    achievementCoins = _useAchievementByType.achievementCoins,
    achievements = _useAchievementByType.achievements;

  // NOTE: Don't reorganize the array, it's ordered by the order of the achievements.
  var _achievementCoins = _slicedToArray(achievementCoins, 4),
    _achievementCoins$ = _achievementCoins[0],
    playroomCourseCoins = _achievementCoins$ === void 0 ? 0 : _achievementCoins$,
    _achievementCoins$2 = _achievementCoins[1],
    playroomProjectCoins = _achievementCoins$2 === void 0 ? 0 : _achievementCoins$2,
    _achievementCoins$3 = _achievementCoins[2],
    playroomQuizCoins = _achievementCoins$3 === void 0 ? 0 : _achievementCoins$3,
    _achievementCoins$4 = _achievementCoins[3],
    playroomLearningPathCoins = _achievementCoins$4 === void 0 ? 0 : _achievementCoins$4;
  var _achievements = _slicedToArray(achievements, 4),
    playroomCourseAchievement = _achievements[0],
    playroomProjectAchievement = _achievements[1],
    playroomQuizAchievement = _achievements[2],
    playroomLearningPathAchievement = _achievements[3];
  return {
    // Feature flag
    isPlayroomEnabled: isPlayroomEnabled,
    // Coins
    playroomQuizCoins: playroomQuizCoins,
    playroomCourseCoins: playroomCourseCoins,
    playroomProjectCoins: playroomProjectCoins,
    playroomLearningPathCoins: playroomLearningPathCoins,
    // Achievement objects
    playroomCourseAchievement: playroomCourseAchievement,
    playroomProjectAchievement: playroomProjectAchievement,
    playroomQuizAchievement: playroomQuizAchievement,
    playroomLearningPathAchievement: playroomLearningPathAchievement
  };
};
export default usePlayroomCoinEventValues;