export var parsePlayroomReward = function parsePlayroomReward(toParse) {
  var _toParse$cover_attach;
  return {
    originalId: toParse.id,
    coins: toParse.currency_point_value || 0,
    title: toParse.name,
    description: toParse.description,
    stock: toParse.quantity_available || 0,
    imageSrc: (_toParse$cover_attach = toParse.cover_attachment) === null || _toParse$cover_attach === void 0 ? void 0 : _toParse$cover_attach.url,
    redemptions_allowed: toParse.redemptions_allowed || 0,
    redemptions_available: toParse.redemptions_available || 0,
    can_redeemed_this_reward: toParse.can_redeemed_this_reward || false,
    tags: toParse.tags || []
  };
};
export var filterPlayroomReward = function filterPlayroomReward(toFilter) {
  return !!toFilter.is_redeemable;
};