import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import { PLAYROOM_ACHIEVEMENTS_SECTION_KEY } from "../../constants";
import locales from "../../i18n";
var usePlayroomAchievementsTranslations = function usePlayroomAchievementsTranslations() {
  var _useTranslation = useTranslation(PLAYROOM_ACHIEVEMENTS_SECTION_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    contextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return contextValue;
};
export default usePlayroomAchievementsTranslations;