import { useEffect, useRef } from 'react';
function useEffectAfterMount(cb, dependencies) {
  var justMounted = useRef(true);
  useEffect(function () {
    if (!justMounted.current) {
      return cb();
    }
    justMounted.current = false;
  }, dependencies);
}
export default useEffectAfterMount;