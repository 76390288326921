/**
 * @description Get the rive artboard name by color achievement.
 * @todo: This function at the future will support more colors with artboard like 'Confetti_new_purple' and 'Confetti_new_yellow'.
 */
export var getArtboardRiveByLogoName = function getArtboardRiveByLogoName(color) {
  var _color = (color || '').toLowerCase();
  switch (_color) {
    case 'primary':
      return 'Confetti_new_blue';
    case 'warning':
      return 'Confetti_new_red';
    case 'success':
      return 'Confetti_new_green';
    case 'alert':
      return 'Confetti_new_red';
    default:
      return 'Confetti_new_blue';
  }
};