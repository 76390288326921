import { colors } from '@crehana/tailwindcss';
function hexToRGBA(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16);
  var g = parseInt(hex.slice(3, 5), 16);
  var b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
  }
  return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")");
}
function extractHexFromString(str) {
  var regex = /#[0-9A-F]{6}/gi;
  var hex = str.match(regex);
  return hex ? hex[0] : undefined;
}

/**
 * @description Transform color name to hex and include alpha to use as background color with transparency.
 */
export var transformColorNameToHex = function transformColorNameToHex() {
  var _colorMap;
  var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'primary';
  var isDark = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var colorMap = {
    primary: isDark ? colors['primary-dark'][500] : colors['primary-light'][400],
    success: colors.success[400],
    warning: colors.warning[400],
    alert: colors.alert[400],
    info: colors.info[400]
  };
  var colorVariable = (_colorMap = colorMap[color]) !== null && _colorMap !== void 0 ? _colorMap : colorMap.success;
  var hex = extractHexFromString(colorVariable);
  if (hex) {
    return hexToRGBA(hex, '0.1');
  }
  return colorMap.success;
};