import fecha from 'fecha';
import { LANGUAGES } from '@crehana/ts-types';
import locales from "./locales";

/**
 * @category Date format
 *
 * @summary Receives a Date object and returns a date as string.
 *
 * @param {Date} date - Date object
 * @param {Object} options - Object describing the format options for the given date
 * @param {format} options.format - You need to specify the output format
 * @param {LANGUAGES} options.languageCode - You need to provide the current language code from @crehana/i18n
 *
 * @example
 * toString(
 *   dateObject,
 *  { format: 'YYYY/MM/DD', languageCode: LANGUAGES.EN },
 * ); // 2022/01/10
 * toString(
 *   dateObject,
 *  { format: 'MMM D, YYYY', languageCode: LANGUAGES.EN },
 * ); // Jan 10, 2022
 */
var toString = function toString(date, options) {
  var format = options.format,
    languageCode = options.languageCode;
  var langKey = Object.values(LANGUAGES).includes(languageCode) ? languageCode : LANGUAGES.ES;
  fecha.i18n = locales[langKey];
  var d = fecha.format(date, format);
  return d.charAt(0).toUpperCase().concat(d.slice(1));
};
export default toString;