import en, { humanized as humanizedEn, remaining as remainingEn } from "./en";
import es, { humanized as humanizedEs, remaining as remainingEs } from "./es";
import pt, { humanized as humanizedPt, remaining as remainingPt } from "./pt";
export var humanized = {
  en: humanizedEn,
  es: humanizedEs,
  pt: humanizedPt
};
export var remaining = {
  en: remainingEn,
  es: remainingEs,
  pt: remainingPt
};
export default {
  en: en,
  es: es,
  pt: pt
};