import _objectSpread from "@babel/runtime/helpers/objectSpread2";
export var parseUserAchievementItem = function parseUserAchievementItem(item, fallbacks) {
  var _node$event, _node$event2, _node$event3, _node$event3$icon, _node$event4, _node$event5, _node$event6, _node$event7, _node$event8, _node$event9, _node$event10;
  var node = item.node;
  return _objectSpread(_objectSpread({}, item.node.event), {}, {
    originalId: (_node$event = node.event) === null || _node$event === void 0 ? void 0 : _node$event.id,
    completed: node.achievement_completed,
    coins: ((_node$event2 = node.event) === null || _node$event2 === void 0 ? void 0 : _node$event2.value) || 0,
    customImageSrc: (_node$event3 = node.event) === null || _node$event3 === void 0 ? void 0 : (_node$event3$icon = _node$event3.icon) === null || _node$event3$icon === void 0 ? void 0 : _node$event3$icon.url,
    title: ((_node$event4 = node.event) === null || _node$event4 === void 0 ? void 0 : _node$event4.custom_description) || '',
    description: ((_node$event5 = node.event) === null || _node$event5 === void 0 ? void 0 : _node$event5.custom_text) || '',
    tag: ((_node$event6 = node.event) === null || _node$event6 === void 0 ? void 0 : _node$event6.custom_name) || '',
    color: (((_node$event7 = node.event) === null || _node$event7 === void 0 ? void 0 : _node$event7.background_color) || 'primary').toLowerCase(),
    urlRedirect: ((_node$event8 = node.event) === null || _node$event8 === void 0 ? void 0 : _node$event8.url_redirect) || fallbacks.myCoursesUrl,
    ctaLabel: ((_node$event9 = node.event) === null || _node$event9 === void 0 ? void 0 : _node$event9.custom_description) || undefined,
    subjectName: ((_node$event10 = node.event) === null || _node$event10 === void 0 ? void 0 : _node$event10.event_subject) || undefined
  });
};