export var getArtboardNameBySubjectName = function getArtboardNameBySubjectName(subjectTypeName) {
  switch (subjectTypeName) {
    case 'COMPLETE_COURSE':
      return 'badge_course_completed';
    case 'COMPLETE_PROJECT':
      return 'badge_project_completed';
    case 'COMPLETE_QUIZ':
      return 'badge_quiz_completed';
    default:
      return 'badge_route_completed';
  }
};