import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import { PLAYROOM_REWARDS_SECTION_PAGE_KEY } from "../../constants";
import locales from "../../i18n/";
var usePlayroomRewardsTranslations = function usePlayroomRewardsTranslations() {
  var _useTranslation = useTranslation(PLAYROOM_REWARDS_SECTION_PAGE_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    contextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return contextValue;
};
export default usePlayroomRewardsTranslations;