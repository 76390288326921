import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CurrencyPointStatusEnum } from "../../types/graphql/globals.gamification";
var GET_USER_SUMMARY_CURRENCY_QUERY = { kind: "Document", definitions: [{ kind: "OperationDefinition", operation: "query", name: { kind: "Name", value: "GetUserSummaryCurrency" }, variableDefinitions: [{ kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "slug" } }, type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }, directives: [] }, { kind: "VariableDefinition", variable: { kind: "Variable", name: { kind: "Name", value: "status" } }, type: { kind: "NamedType", name: { kind: "Name", value: "CurrencyPointStatusEnum" } }, directives: [] }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "currency_point_detail" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "status" }, value: { kind: "Variable", name: { kind: "Name", value: "status" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "name" }, arguments: [], directives: [] }] } }, { kind: "Field", name: { kind: "Name", value: "user_summary_detail" }, arguments: [{ kind: "Argument", name: { kind: "Name", value: "slug" }, value: { kind: "Variable", name: { kind: "Name", value: "slug" } } }], directives: [], selectionSet: { kind: "SelectionSet", selections: [{ kind: "Field", name: { kind: "Name", value: "available_currency_points" }, arguments: [], directives: [] }] } }] } }], loc: { start: 0, end: 331, source: { body: "query GetUserSummaryCurrency($slug: String!, $status: CurrencyPointStatusEnum) {\n  currency_point_detail(status: $status) {\n    # 'name' corresponds to the custom field name of the currency points.\n    # E.g. 'name': 'PointTest', 'CrehanaCoins'\n    name\n  }\n  user_summary_detail(slug: $slug) {\n    available_currency_points\n  }\n}\n", name: "GraphQL request", locationOffset: { line: 1, column: 1 } } } };
var useAvailableCurrencyByUser = function useAvailableCurrencyByUser(organizationSlug, args) {
  var _data$currency_point_, _data$currency_point_2, _data$user_summary_de, _data$currency_point_3;
  var shouldSkipCurrencyQuery = typeof (args === null || args === void 0 ? void 0 : args.currentCoins) === 'number' && args.currentCoins >= 0;
  var variables = useMemo(function () {
    return {
      slug: organizationSlug,
      status: CurrencyPointStatusEnum.PUBLISHED
    };
  }, [organizationSlug]);
  var _useQuery = useQuery(GET_USER_SUMMARY_CURRENCY_QUERY, {
      context: {
        clientName: 'v5'
      },
      variables: variables,
      skip: shouldSkipCurrencyQuery
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error,
    refetch = _useQuery.refetch;
  var refetchQuery = useCallback(function () {
    return refetch(_objectSpread({}, variables));
  }, [refetch, variables]);
  var currencyCustomName = (_data$currency_point_ = data === null || data === void 0 ? void 0 : (_data$currency_point_2 = data.currency_point_detail) === null || _data$currency_point_2 === void 0 ? void 0 : _data$currency_point_2.name) !== null && _data$currency_point_ !== void 0 ? _data$currency_point_ : 'CrehanaCoins';
  var crehanaCoinsCurrentUser = (shouldSkipCurrencyQuery ? args.currentCoins : data === null || data === void 0 ? void 0 : (_data$user_summary_de = data.user_summary_detail) === null || _data$user_summary_de === void 0 ? void 0 : _data$user_summary_de.available_currency_points) || 0;
  var hasCurrency = Boolean(data === null || data === void 0 ? void 0 : (_data$currency_point_3 = data.currency_point_detail) === null || _data$currency_point_3 === void 0 ? void 0 : _data$currency_point_3.name);
  return {
    crehanaCoinsCurrentUser: crehanaCoinsCurrentUser,
    currencyCustomName: currencyCustomName,
    hasCurrency: hasCurrency,
    loading: loading,
    refetch: refetchQuery,
    error: error
  };
};
export default useAvailableCurrencyByUser;