import { STANDARD_DATE_FORMAT } from '@crehana/constants';
import toDate from "./toDate";
import toString from "./toString";

/**
 * @category Date format
 *
 * @summary Receives a string or Date object and returns it in the given format.
 *
 * @param {string | Date} dateToFormat - Date object or date as string
 * @param {Object} options - Object describing the format options for the given date
 * @param {string} options.outputFormat - Format expected
 * @param {inputFormat} options.inputFormat - If you provide the date as a string, you can specify the input format, otherwise it will be assumed to have the STANDARD_DATE_FORMAT
 * @param {LANGUAGES} options.languageCode - You need to provide the current language code from @crehana/i18n
 *
 * @example
 * const fullDate = '2017-12-21T22:09:55.617047+00:00';
 * const dateWithCustomFormat = '2017/12/21';
 *
 * format(
 *   fullDate,
 *   { outputFormat: 'MMM D, YYYY', languageCode: LANGUAGES.ES },
 * ); // Dic 21, 2017
 * format(
 *   sateWithCustomFormat,
 *  { outputFormat: 'MMM D, YYYY', inputFormat: 'YYYY/MM/DD', languageCode: LANGUAGES.ES },
 * ); // Dic 21, 2017
 */
var formatDate = function formatDate(dateToFormat, options) {
  var outputFormat = options.outputFormat,
    _options$inputFormat = options.inputFormat,
    inputFormat = _options$inputFormat === void 0 ? STANDARD_DATE_FORMAT : _options$inputFormat,
    languageCode = options.languageCode;
  var date = typeof dateToFormat === 'string' ? toDate(dateToFormat, {
    inputFormat: inputFormat,
    languageCode: languageCode
  }) : dateToFormat;
  if (date) {
    return toString(date, {
      format: outputFormat,
      languageCode: languageCode
    });
  }
  return '';
};
export default formatDate;