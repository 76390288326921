import fecha from 'fecha';
import { STANDARD_DATE_FORMAT } from '@crehana/constants';
import { LANGUAGES } from '@crehana/ts-types';
import locales from "./locales";

/**
 * @category Date format
 *
 * @summary Receives a date as string and returns a Date object.
 *
 * @param {string} dateString - Date as string
 * @param {Object} options - Object describing the format options for the given date
 * @param {inputFormat} options.inputFormat - You can specify the input format, otherwise it will be assumed to have the STANDARD_DATE_FORMAT
 * @param {LANGUAGES} options.languageCode - You need to provide the current language code from @crehana/i18n
 *
 * @example
 * toDate(
 *   '2017/12/21',
 *  { inputFormat: 'YYYY/MM/DD', languageCode: LANGUAGES.ES },
 * );
 */
var toDate = function toDate(dateString, options) {
  var _options$inputFormat = options.inputFormat,
    inputFormat = _options$inputFormat === void 0 ? STANDARD_DATE_FORMAT : _options$inputFormat,
    languageCode = options.languageCode;
  var langKey = Object.values(LANGUAGES).includes(languageCode) ? languageCode : LANGUAGES.ES;
  fecha.i18n = locales[langKey];
  var parsedDate = fecha.parse(dateString, inputFormat);
  return parsedDate instanceof Date ? parsedDate : undefined;
};
export default toDate;